import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexMilgauss = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexMilgauss {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Milgauss/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Milgauss Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Milgauss Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/search/#search=rolex+milgauss">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-milgauss-header.png"
                alt="Pre-Owned Certified Used Rolex Milgauss Watches Header"
                aria-label="Used Rolex Milgauss Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED ROLEX MILGAUSS</h1>
            <h2>
              ROLEX MILGAUSS: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES{' '}
            </h2>
            <h3>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX WATCHES</h3>
            <p>
              <br />
              <b>About Used Rolex Milgauss Watch</b>
              <br />
              <br />
              The Rolex Milgauss watch made its official debut in 1956, created specifically for
              members of the scientific community who often worked in environments with
              high-magnetic fields. Magnetism is highly detrimental to watch movements as it can
              cause them to lose accuracy. The name Milgauss is a combination of the words “mille”
              (French for 1,000) and “gauss” (a measurement of magnetism.) Rolex Milgauss watches
              were developed to be resistant to magnetic fields of up to 1,000 gauss by using a soft
              iron shield around the movement.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Milgauss Watches</b>
              <br />
              <br />
              While the Milgauss dates back to the 1950s, its history was interrupted from the
              late-1980s until the mid-2000s when Rolex stopped making it. However, in 2007, it was
              revived and the modern Rolex Milgauss remains a part of the current catalog in the
              form of the reference 116400GV. The 116400 without green sapphire crystals were
              discontinued a few years back; therefore, they can only be sourced as secondhand Rolex
              Milgauss watches. Below are the pre-owned Rolex Milgauss watch references you can find
              in the secondary watch market.
              <br />
              <br />
              Popular Pre-Owned Used Rolex Milgauss Watch References:
              <ul>
                <li>
                  Current Milgauss ref. 116400GV; 40mm steel case, green sapphire crystal, black or
                  z-blue dial, orange lightning bolt
                </li>
                <li>
                  Discontinued Milgauss ref. 116400; 40mm steel case, white or black dial, orange
                  lightning bolt seconds hand
                </li>
                <li>
                  Vintage Milgauss ref. 1019 (1960 – 1988); 38mm steel case, domed bezel, black or
                  silver dial, straight arrow-tipped seconds hand
                </li>
                <li>
                  Vintage Milgauss ref. 6541 (1956 – 1960): 38mm steel case, rotating timing bezel,
                  black dial, lightning bolt seconds hand
                </li>
                <li>
                  Vintage Prototype Milgauss ref. 6543 (pre-1956): 38mm steel case, rotating timing
                  bezel, black dial, straight seconds hand
                </li>
              </ul>
              <br />
              <br />
              As an independent Rolex dealer, Gray &amp; Sons benefits from inventory control and
              price flexibility. Every preowned Rolex Milgauss watch for sale on our website is in
              stock, ready to ship immediately. Our
              <a href="/chat-with-rich">decision-makers </a> are on standby when you want to make a
              deal on a secondhand Milgauss watch — or any other used Rolex watch in our inventory.
              On the other hand, if you’ve asked yourself the question, “Where can I sell my used
              Rolex Milgauss men’s watch?” you can get the best cash offer by visiting
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a>
              <br />
              <br />
              <b>Buying and Selling Used Rolex Milgauss Watches</b>
              <br />
              <br />
              For over 40 years, Gray &amp; Sons served thousands of happy customers at both our
              physical store (located next to the Bal Harbour Shops in Surfside/Miami, open six days
              a week) and through our premier e-commerce site. We’re proud that so many of our
              satisfied clients name Gray &amp; Sons the number one buyer and seller of pre-owned
              used Rolex Milgauss watches. The Gray &amp; Sons store serves the Miami, Miami Beach,
              Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura,
              Fort Lauderdale, Pinecrest, and Brickle areas.
              <br />
              <br />
              Whether in-store or on our website, our watch experts are ready to assist you to sell
              or buy pre-owned Rolex Milgauss watches. We also have an independent Rolex Milgauss
              watch repair facility for all your maintenance needs.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/search/#search=rolex+milgauss">
                <button>SHOP ROLEX MILGAUSS WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX MILGAUSS WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexMilgauss
